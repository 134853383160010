<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      CoreAppBar: () => import('@/views/components/core/AppBar'),
      CoreDrawer: () => import('@/views/components/core/Drawer'),
      CoreView: () => import('@/views/components/core/View')
    },
    data: () => ({
      expandOnHover: false
    })
  };
</script>
